.name{
    display: flex;
    justify-content: center;
    padding-bottom: 0.5%;
    font-size: xx-large;
}
.themeImg{
    max-width: 60%;
    border-radius: 10px;
}
.post{
    display: flex;
    justify-content: center;
}
.regulation{
    height: 7vh;
}
.text{
    display: flex;
    justify-content: center;
    padding: 0 5% 2% 5%;
    text-align: center;
}


@media screen and (max-width: 1060px) {
    .themeImg{
        max-width: 80vw;
    }
}