.main {
    margin-bottom: 7%;
    align-items: center;
    justify-content: center;
}

.leftPost .mainImg {
    margin-left: 5%;
    width: 45vw;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.rightPost .mainImg {
    margin-left: 2%;
    width: 45vw;
    margin-bottom: 5%;
    border-radius: 10px;
}

.leftPost {
    display: flex;
}

.leftPost .description {
    width: 45vw;
    margin-left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}

.name,
.transport {
    width: 100vw;
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.rightPost {
    display: flex;
}

.rightPost .description {
    width: 45vw;
    margin-left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
}

.name {
    font-size: xx-large;
    color: black;
    align-items: center;
    padding-bottom: 0vw;
}

.shortDesc {
    color: black;
    font-weight: bolder;
    font-size: 1.05rem;
    text-align: center;

}

.transport {
    font-size: 1.1rem;
    padding: 6px;
    padding-bottom: 3vw;

}

.longDesc {
    color: grey;
    font-size: 0.95rem;
    text-align: justify;
}

.description h2 {
    color: green;
    display: flex;
    justify-content: center;
}

.regulation {
    height: 7vh;
}

.list {
    list-style: inside circle;
}

.margin {
    margin-top: 1%;
}

@media screen and (max-width: 1060px) {
    .shortDesc {
        font-size: 1rem;
    }

    .leftPost {
        display: grid;
        grid-template-rows: 2;
        margin-bottom: 8%;
    }

    .leftPost .description {
        grid-row: 1/2;
        width: 90%;
        margin: 0 auto 1% auto;
    }

    .leftPost .mainImg {
        grid-row: 2/3;
        width: 90%;
        margin: 0 auto 5% auto;
    }

    .rightPost {
        display: grid;
        grid-template-rows: 2;
        margin-bottom: 8%;
    }

    .rightPost .description {
        grid-row: 1/2;
        width: 90%;
        margin: 0 auto 1% auto;
    }

    .rightPost .mainImg {
        grid-row: 2/3;
        width: 90%;
        margin: 0 auto 5% auto;
    }

    .transport {
        font-size: 0.95rem;
        text-align: center;
    }



}

@media screen and (max-width: 400px){
    .longDesc{
        text-align: left;
    }
}