.general{
    width: 100vw;
    max-width: 100%;
    background-color: rgba(211, 211, 211, 0.429);
    display: flex;
    flex-wrap: wrap;
    padding: 100px 100px 100px 100px;
    color: rgb(106, 106, 106);
    justify-content: center;
}

.tag{
    padding-top: 0%;
    color: rgb(10, 118, 10);
    font-size: 38px;
    font-weight: 1000;
    font-family: Kanit, sans-serif;
}

.subtitle{
    width: 300px;
}

.text1{
    color: black;
}

.text1, .text2{
    padding: 3%;
    width: 50%;
    text-align: justify;
    max-width: 600px;
}

.topDescription{
    margin-top: 30px;
}

.bottomDescription{
    padding-top: 20px;
}

@media screen and (max-width: 1060px) {
    .text1, .text2{
        width: 100vw;
        max-width: 100%;
    }

    .general{
        padding: 3%;
        padding-bottom: 50px;
    }

    .subtitle{
        width: 200px;
        padding-bottom: 10px;
    }

    .tag{
        font-size: 35px;
    }

    .topDescription{
        margin-top: 0px;
    }

    .description{
        padding-top: 20px;
    }
}

@media screen and (max-width: 340px) {
    .text1, .text2{
        text-align: left;
    }
}
