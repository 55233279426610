.container{
    background-color: rgba(0, 80, 0, 0.649);
    height: 3vh;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.bottomContainer{
    color: white;
    text-align: center;
}

.emailLink{
    font-weight: lighter;
    text-decoration: none;
    color: white;
    margin-left: 0px;
}

@media screen and (max-width: 400px) {
    .container{
        padding: 0 10px;
    }
}