.nav {
    position: fixed;
    display: flex;
    font-size: medium;
    height: 7vh;
    width: 100%;
    min-height: 40px;
}

.menu {
    height: 100%;
    width: 100%;
    display: inline-flex;
    align-items: center;
}

.menu ul {
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.menu li {
    list-style: none;
}

.carImg {
    height: 25px;
    padding: 0 5px;
    padding-left: 25px;
}

.subtitleImg {
    max-height: 18px;
    max-width: 50vw;
}

.logoContainer {
    height: 23px;
    display: flex;
    align-items: center;
}

.partLink {
    text-decoration: none;
    color: white;
}

.partLink2 {
    text-decoration: none;
    color: black;
}

.menulist {
    visibility: hidden;
    margin: 0;
    padding: 0;
}

.menulist:active {
    background-color: rgba(0, 0, 0, 0.455);
}

.partLink:hover {
    color: rgb(255, 205, 139);
}

.partLink2:hover {
    color: rgb(255, 205, 139);
}

/* mobile configuration */
.mobileContainer {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    position: fixed;
    z-index: 1000;
    transition: margin-left 0.3s ease-out;
}

.mobileMenu{
    width: 85vw;
    background-color: rgba(0, 0, 0, 0.885);
    height: 100vh;
}

.mobileUl {
    text-align: center;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.152);
}

.mobileListPart {
    border-bottom: 1px solid rgba(255, 255, 255, 0.152);
}

.mobilePartLink {
    border-top: 10%;
    padding: 5%;
    padding-left: 25%;
    padding-right: 25%;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.727);
}

.mobilePartLinkDiv {
    margin-top: 5%;
    margin-bottom: 5%;
}

.mobilePartLink:active {
    color: black;
    background-color: darkgreen;
}

@media screen and (max-width: 1060px) {
    body {
        font-size: 1rem;
    }

    .carImg{
        padding-left: 5px;
    }

    .nav .webUl {
        visibility: collapse;
        width: 0;
        justify-content: center;
    }

    .menulist {
        height: 22px;
        visibility: visible;
        position: absolute;
        right: 20px;
        /* top: 15px; */
    }


}

@media screen and (min-width: 1070px) {
    .mobileContainer {
        visibility: hidden;
    }
}

@media screen and (min-device-aspect-ratio: 1060/670) {
    .mobilePartLinkDiv {
        margin-top: 2%;
        margin-bottom: 2%;
    }

    .mobilePartLink {
        padding: 2.5%;
        padding-left: 25%;
        padding-right: 25%;
    }
}

@media screen and (max-width: 281px){
    .mobileListPart{
        font-size: 14px;
    }
}