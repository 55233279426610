.main {
    padding: 1% 10% 1% 10%;
    background-color: rgba(0, 80, 0, 0.649);
    height: 60vh;
    margin-bottom: 1vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile {
    padding: 3%;
    background-color: antiquewhite;
    height: 90%;
    width: 27%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-align: center;
}

.regulation {
    height: 7vh;
}

.name {
    height: 30%;
    width: 100%;
}

.contact {
    list-style: none;
    height: 30%;
    width: 100%;
}

.list {
    color: green;
}

.bigPhone {
    width: 8vw;
    border-radius: 3%;

}

.bigEmail {
    width: 8vw;
    border-radius: 3%;
}

.bigPhone:hover {
    background-color: rgba(0, 133, 2, 0.477);
    transition: background-color 1s;
    border: 5px solid;
    border-color: rgba(240, 248, 255, 0);
    border-radius: 3%;
}

.bigEmail:hover {
    background-color: rgba(0, 133, 2, 0.477);
    border: 5px solid;
    border-color: rgba(240, 248, 255, 0);
    border-radius: 3%;
}

.list a {
    text-decoration: none;
    color: green;
}

@media screen and (max-width: 1060px) {
    .main {
        display: inline-block;
        height: 200vw;
        min-height: 93vh;
        max-height: 1000px;
        width: 99.99vw;
        padding: 0;
        padding-top: 15%;
    }

    .profile {
        display: flex;
        width: 99.99vw;
        height: 60vw;
        max-height: 250px;
        margin-bottom: 2px;
        border-radius: 0;
        align-items: center;
        justify-content: center;
    }

    .bigPhone {
        width: 14vw;
    }

    .bigEmail {
        width: 10vw;
    }
}