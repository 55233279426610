*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;
  transition: background-color 0.5s, color 0.5s;

}
body{
  background-color: white;
}
html, body{
  scroll-behavior: smooth;
}